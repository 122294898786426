import { ContentLayout } from '~/components/admin-panel/content-layout';
import PlaceholderContent from '~/components/placeholder-content';
import { checkAdminPermissions } from '~/lib/auth.server';
import { RouteHandle } from '~/lib/route-handle';
import { getSeoMeta } from '~/lib/seo';
import { Route } from './+types/_index';

export const handle: RouteHandle = {
  breadcrumbText: 'Welcome',
};

export const loader = async (args: Route.LoaderArgs) => {
  await checkAdminPermissions(args);
  return {};
};

export const meta: Route.MetaFunction = (args) => {
  return getSeoMeta(
    {
      title: 'Courses Admin',
    },
    // @ts-expect-error - this is a hack to pass the args to getSeoMeta
    args
  );
};

export default function IndexPage() {
  return (
    <>
      <ContentLayout title="Welcome">
        <PlaceholderContent />
      </ContentLayout>
    </>
  );
}
